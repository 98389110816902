import { createSlice } from '@reduxjs/toolkit'
import { createTransform } from 'redux-persist'

export const initialState = {
    tabLanguages: ['en'],
}

const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        setCountryLanguage: (state, { payload }) => {
            // state.tabLanguages[1] = payload ? payload.toLowerCase() : payload
            if (payload) state.tabLanguages = [payload, ...initialState.tabLanguages];
        },
        addCountryLanguage: (state, { payload }) => {
            state.tabLanguages = [...initialState.tabLanguages, payload];
        },
    },
})

export const CountryPersistTransform = createTransform(
    (inboundState) => inboundState,
    (outBoundState) => outBoundState,
    { whitelist: ['countryPersist'] }
)

export const { setCountryLanguage, addCountryLanguage } = countrySlice.actions
export const selectLanguage = (state) => state.country.tabLanguages
export default countrySlice.reducer
