import { useEffect, useRef } from 'react'
import socketIOClient from 'socket.io-client'
import { useSelector } from 'react-redux'

const socketIoHost = process.env.REACT_APP_SOCKET_URL || 'https://localhost'
const url = socketIoHost

const useSocket = () => {
    const socketRef = useRef()
    const { id, role } = useSelector((state) => state.user)
    const isCountry = role === 'country'

    useEffect(() => {
        if (isCountry && process.env.NODE_ENV === 'production') {
            // create a websocket connection
            socketRef.current = socketIOClient(url)

            // join room
            socketRef.current.on('connect', () => {
                socketRef.current.emit('room', `country.${id}`)
            })

            socketRef.current.on('connect_error', () => {
                socketRef.current.disconnect()
            })
        }

        // disconnect
        return () => {
            if (isCountry && socketRef && socketRef.current) {
                socketRef.current.disconnect()
            }
        }
    }, [])

    return socketRef.current
}

export default useSocket
