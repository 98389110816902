import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit'
import {
    persistStore,
    persistReducer,
    createMigrate,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import userReducer, { UserPersistTransform } from '@/features/user/userSlice'
import threadReducer, { initialState as threadState } from '@/features/thread/threadSlice'
import tableReducer from '@/features/table/tableSlice'
import { updatePermissions } from '@/ability'
import countrySlice, { CountryPersistTransform } from '@/features/country/countrySlice'

const rootReducer = combineReducers({
    user: userReducer,
    thread: threadReducer,
    table: tableReducer,
    country: countrySlice,
})

const migrations = {
    3: (state) => {
        return {
            ...state,
            thread: {
                ...state.thread,
                checkboxMode: threadState.checkboxMode,
                checkedItems: threadState.checkedItems,
                images: threadState.images,
                activeImageIndex: threadState.activeImageIndex,
                imageViewerVisible: threadState.imageViewerVisible,
            },
        }
    },
}

const persistConfig = {
    key: 'user',
    version: 3,
    storage,
    transforms: [UserPersistTransform, CountryPersistTransform],
    migrate: createMigrate(migrations, { debug: false }),
    blacklist: [],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
})

const rehydrateCallback = () => {
    const { user } = store.getState()

    updatePermissions(user)
}

export const persistor = persistStore(store, null, rehydrateCallback)
