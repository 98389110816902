import React, { useState, useEffect } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Form, Input, Checkbox, Button, notification, Row, Col, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuthStatus, setUserData, setAuthSession, logout } from '@/features/user/userSlice'
import Api, { user } from '@/Api'
import { mutateErrorData, emailIcon, passwordIcon, clearErrorOnFocus } from '@/helpers/authHelper'
import AuthLayout from './AuthLayout'
import { addCountryLanguage } from '@/features/country/countrySlice'

const initialValues = { email: '', password: '', remember: false }
const initialErrors = { email: {}, password: {} }

const Login = () => {
    const authStatus = useSelector(selectAuthStatus)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(initialErrors)

    const location = useLocation()
    const history = useHistory()

    const auth = async (values) => {
        setLoading(true)
        setErrors(initialErrors)

        try {
            const { data } = await Api.request(user.login, values)
            try {
                const response = await Api.request(user.data)
                const payload = { ...response.data.data, ...data }
                localStorage.removeItem('member_role')

                dispatch(setAuthSession({ remember: values.remember }))
                dispatch(setUserData(payload))

                if (data.role === 'member') {
                    const { isPdmAdmin } = response.data.data
                    if (isPdmAdmin) {
                        localStorage.setItem('member_role', 'pdm-admin')
                    }
                }

                if (!data.is_manager && (data.role === 'member' || data.role === 'country')) {
                    dispatch(addCountryLanguage(payload.country_code || payload.country.country_code))
                }
            } catch {
                dispatch(logout())
            }
        } catch (err) {
            setLoading(false)
            notification.error({
                message: 'Authentication Error',
                description: err.response.data.message || '',
            })
            const newErrors = mutateErrorData(err.response.data, initialErrors)
            setErrors(newErrors)
        }
    }

    useEffect(() => {
        if (authStatus) {
            const defaultFrom = { from: { pathname: '/' } }
            if (sessionStorage.getItem('clear_login_redirect')) {
                sessionStorage.removeItem('clear_login_redirect')
                const { from } = defaultFrom
                history.replace(from)
            } else {
                const { from } = location.state || defaultFrom
                history.replace(from)
            }
        }
    }, [authStatus])

    return (
        <AuthLayout>
            <Row justify="center">
                <Col>
                    <Typography.Title level={2}>Sign in</Typography.Title>
                </Col>
            </Row>
            <Form
                name="login"
                size="large"
                onFinish={auth}
                requiredMark={false}
                layout="vertical"
                colon={false}
                initialValues={initialValues}
            >
                <Form.Item name="email" {...errors.email}>
                    <Input
                        name="email"
                        placeholder="Email"
                        prefix={emailIcon}
                        onFocus={(e) => clearErrorOnFocus(e, setErrors)}
                        disabled={loading}
                    />
                </Form.Item>

                <Form.Item name="password" {...errors.password}>
                    <Input.Password
                        name="password"
                        placeholder="Password"
                        prefix={passwordIcon}
                        onFocus={(e) => clearErrorOnFocus(e, setErrors)}
                        disabled={loading}
                    />
                </Form.Item>

                <Form.Item name="remember" valuePropName="checked" className="auth-checkbox-form-item">
                    <Checkbox disabled={loading}>Remember me</Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" block loading={loading}>
                        Sign in
                    </Button>
                </Form.Item>
            </Form>

            <Row justify="center">
                <Col>
                    <Link to="/forgot-password" className="auth-link">
                        Forgot password
                    </Link>
                </Col>
            </Row>
        </AuthLayout>
    )
}

export default Login
